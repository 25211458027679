/**
 * 列表对象多级嵌套搜索
 * @param data {any} 数组
 * @param keyword {string} 关键字
 */
const search = (data: any, keyword: string) => {
  if (keyword) {
    return data.filter((v: any) => {
      return Object.keys(v).some((key) => {
        return String(v[key]).toLowerCase().indexOf(keyword) > -1
      })
    })
  }
  return data;
}
export default search;
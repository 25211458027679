
import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import {
  allParentList,
  createDict,
  updateDict,
  deleteDict,
  allList,
} from "@/api/system";
import { Dictionary } from "@/types/api/system";
import search from "@/utils/search";
import debounce from "@/utils/debounce";
import Loading from "@/mixins/loading";
import TablePagination from "@/mixins/tablePagination";
import copy from "@/utils/deepCopy";

@Component
export default class DataDictionary extends Mixins(Loading, TablePagination) {
  initData: any = [];
  tableData: any = []; // 数据列表
  keyword: string = ""; // 搜索关键字
  dialogVisible: boolean = false; //对话框
  // 初始化表单
  initForm: Dictionary = {
    dictDesc: "",
    dictKey: "",
    dictValue: "",
    level: 0,
    parentId: 0,
    sort: 0,
  };
  form: Dictionary = this.initForm; // 表单
  type: string = "add"; // add:添加  edit:编辑
  disabled: boolean = true;

  /**
   * 生命周期
   */
  mounted() {
    this.getData();
  }
  /**
   * 监听
   */
  @Watch("keyword")
  searchValue() {
    debounce(() => this.handleSearch());
  }
  /**
   * methods
   */
  getData(): void {
    this.showLoading();
    allList().then((res: any) => {
      this.tableData = res.data;
      this.initData = res.data;
      this.hideLoading();
    });
  }
  // 搜索
  handleSearch() {
    this.showLoading();
    setTimeout(() => {
      this.tableData = search(this.initData, this.keyword);
      this.hideLoading();
    }, 500);
  }
  // 添加
  handleAdd() {
    this.disabled = false;
    this.type = "add";
    this.dialogVisible = true;
  }
  // 添加子字典
  handleAddChild(row: Dictionary) {
    this.disabled = true;
    this.type = "add";
    this.dialogVisible = true;
    // @ts-ignore
    this.form.parentId = row.id;
  }
  // 编辑
  handleEdit(row: Dictionary) {
    this.disabled = false;
    this.type = "edit";
    this.dialogVisible = true;
    this.form = copy(row);
  }
  // 删除
  handleDelete(id: number) {
    this.$confirm(`是否要删除该字典 (id:${id}) ?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      deleteDict({
        id,
      }).then((res: any) => {
        this.$message.success("删除成功");
        this.getData();
      });
    });
  }
  // 确认添加/修改
  async confirm() {
    let promi: any;
    if (this.type == "add") {
      promi = await createDict(this.form);
    } else {
      promi = await updateDict(this.form);
    }
    if (promi.code == 200) {
      this.$message.success(this.type == "add" ? "添加成功" : "修改成功");
      this.form = JSON.parse(JSON.stringify(this.initForm));
      this.dialogVisible = false;
      this.getData();
    }
  }
}

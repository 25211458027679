import { service } from '@/ajax/request'
import { AxiosPromise } from 'axios'
import { Id, Page } from '@/types/common/index'
import {SaveDesc, Dictionary, DictPage, Carousel, SysAddress, SysAddressParam} from '@/types/api/system'

// 设置 id=1 关于我们，id=2 权益说明 id= 3售后规则 4返佣规格说明
export const saveDesc = (params: SaveDesc): AxiosPromise => (
  service({
    url: `/sys/desc/save/${params.id}`,
    method: 'POST',
    params: params.sysDesc
  })
)

// 获取 id=1 关于我们，id=2 权益说明 id= 3售后规则 4返佣规格说明
export const getDesc = (params: Id): AxiosPromise => (
  service({
    url: `/sys/desc/${params.id}`,
    method: 'GET'
  })
)

// 查询所有的父亲字典
export const allParentList = (): AxiosPromise => (
  service({
    url: `/sys/dict/allParentList`,
    method: 'GET',
    type: 'form'
  })
)

// 查询所有的数据字典，属性
export const allList = (): AxiosPromise => (
  service({
    url: `/sys/sysDict/list`,
    method: 'GET',
    type: 'form'
  })
)

// 添加数据字典
export const createDict = (params: Dictionary): AxiosPromise => (
  service({
    url: `/sys/sysDict/save`,
    method: 'POST',
    params
  })
)

// 删除数据字典分类
export const deleteDict = (params: Id): AxiosPromise => (
  service({
    url: `/sys/sysDict/delete/${params.id}`,
    method: 'POST',
    params,
    type: 'form'
  })
)

// 分页查询数据字典
export const checkDict = (params: DictPage): AxiosPromise => (
  service({
    url: `/sys/dict/list/${params.parentId}`,
    method: 'GET',
    params,
    type: 'form'
  })
)

// 修改数据字典分类
export const updateDict = (params: Dictionary): AxiosPromise => (
  service({
    url: `/sys/sysDict/update/${params.id}`,
    method: 'POST',
    params
  })
)

// 获取所有省份信息
export const getProvinceByCode = (params: { provinceCode: number }): AxiosPromise => (
  service({
    url: `/sys/region/getProvinceByCode`,
    method: 'GET',
    params,
    type: 'form'
  })
)

// 获取城市信息
export const getCity = (params: { provinceCode: string }): AxiosPromise => (
  service({
    url: `/sys/region/getCity`,
    method: 'GET',
    params
  })
)

// 获取区域信息
export const getArea = (params: { cityCode: string }): AxiosPromise => (
  service({
    url: `/sys/region/getArea`,
    method: 'GET',
    params
  })
)

// 获取所有省份信息
export const allProvinces = (): AxiosPromise => (
  service({
    url: `/sys/region/allProvinces`,
    method: 'GET',
    type: 'form'
  })
)

// 获取轮播图片对象
export const getCarouselItem = (params: Id): AxiosPromise => (
  service({
    url: `/sys/picture/getItem/${params.id}`,
    method: 'GET',
    type: 'form'
  })
)

// 删除轮播图片对象
export const deleteCarouselItem = (params: Id): AxiosPromise => (
  service({
    url: `/sys/picture/delete/${params.id}`,
    method: 'GET',
    type: 'form'
  })
)

// 保存轮播图片 type = 1、首页轮播图片，type= 2、会员轮播图片
export const saveCarouselItem = (params: Carousel): AxiosPromise => (
  service({
    url: `/sys/picture/save/${params.type}`,
    method: 'POST',
    params
  })
)

// 修改轮播图片 type = 1、首页轮播图片，type= 2、会员轮播图片
export const updateCarouselItem = (params: Carousel): AxiosPromise => (
  service({
    url: `/sys/picture/update/${params.id}`,
    method: 'POST',
    params
  })
)

// 获取轮播图片列表 type = 1、首页轮播图片，type= 2、会员轮播图片
export const getCarousel = (params: { type: number }): AxiosPromise => (
  service({
    url: `/sys/picture/${params.type}`,
    method: 'GET',
    type: 'form'
  })
)

// 获取用户需求列表
export const demandList = (params: Page): AxiosPromise => (
  service({
    url: `/member/demand/list`,
    method: 'GET',
    type: 'form',
    params
  })
)

// 删除用户需求
export const deleteDemand = (params: { id: number }): AxiosPromise => (
  service({
    url: `/member/demand/delete/${params.id}`,
    method: 'POST',
    type: 'form'
  })
)


// 查询所有的数据字典，属性
export const sysAddressList = (params:SysAddressParam): AxiosPromise => (
    service({
        url: `/system/address/list`,
        method: 'GET',
        params,
        type: 'form'
    })
)
// 查询单个地址
export const getSysAddressItem = (params: Id): AxiosPromise => (
    service({
        url: `/system/getAddress/${params.id}`,
        method: 'GET',
        type: 'form'
    })
)
// 删除自提地址
export const deleteSysAddress = (params: Id): AxiosPromise => (
    service({
        url: `/system/deleteAddress/${params.id}`,
        method: 'POST',
        type: 'form'
    })
)
export const setDefaultSysAddress = (params: Id): AxiosPromise => (
    service({
        url: `/system/setDefaultAddress/${params.id}`,
        method: 'POST',
        type: 'form'
    })
)

// 保存地址
export const saveAddress = (params: SysAddress): AxiosPromise => (
    service({
        url: `/system/saveAddress`,
        method: 'POST',
        type: 'form',
        params
    })
)




